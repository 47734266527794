// here you put all the js you want.
import Navbar from "./js/navbar.js";
const navbar = new Navbar()


function animationDesktop () {
  // scroll trigger
  gsap.registerPlugin(ScrollTrigger);
      

  // declaration de mes différentes timelines (une par scène)

  const tl0 = gsap.timeline();

  const tl1 = gsap.timeline({
      scrollTrigger: {
      trigger: ".partie-1",
      scrub: 0.1,
      pin: true,
      start: "center center",
      end: "+=50%",
      //markers: true,
    }
  });


  // carte de france

  const tl2 = gsap.timeline({
    scrollTrigger: {
    trigger: ".scene-03",
    scrub: 0.5,
    //pin: true,
    start: 'top 55%',
    end: 'bottom 25%',
    //markers: true,
  }
  });

  const tl2_piece = gsap.timeline({
    scrollTrigger: {
    trigger: ".scene-03",
    scrub: 0.5,
    //pin: true,
    start: 'top 55%',
    end: 'bottom 25%',
    //markers: true,
    }
  });

  // epargne

  const tl3 = gsap.timeline({
    scrollTrigger: {
    trigger: ".scene-04",
    //scrub: 0.5,
    //toggleActions: "play none reset reset",
    toggleActions: "play none",
    start: "center center",
    end: "+=20%",
    //markers: true,
  }
  });

  // credit

  const tl4 = gsap.timeline({
      scrollTrigger: {
      trigger: ".scene-05",
      //scrub: 0.5,
      //toggleActions: "restart none pause reset",
      toggleActions: "play none",
      //start: "center center",
      start: "top center",
      end: "+=20%",
      //markers: true,
    }
  });

  // economie locale

  const tl5 = gsap.timeline({
    scrollTrigger: {
    trigger: ".scene-06",
    //scrub: 0.2,
    //pin: true,
    //toggleActions: "restart none reverse reset",
    toggleActions: "play none",
    //start: "center center",
    start: "top center",
    end: "+=50%",
    //markers: true,

  }
  });



  // pour chacune de mes timemline je vais définir les differentes micro animations

  tl0.fromTo(".scene-01", { opacity: 0, scale:1}, {duration: 0.5, scale:1, opacity: 1, ease: "easeIn"})
      .fromTo(".el-00 polyline",  { drawSVG:0}, {duration: 2, stagger: 0.1, drawSVG:true, ease: "easeIn"})
      .fromTo("#logobp", { opacity: 0, scale:1}, {duration: 0.5, scale:1, opacity: 1, ease: "easeIn"})
      //.fromTo(".el-01", { opacity: 0, y: 0}, { duration: 0.5, y: -10, opacity: 1, ease: "easeIn"})
      //.fromTo(".el-02", { opacity: 0, scale:1}, { duration: 0.5, y: -10, opacity: 1, ease: "easeIn"})
      .fromTo('.el-03', { opacity: 0.5, scale:1.5, y:0}, {duration: 1.5, scale:1.5, opacity: 1, y:30, ease: "easeIn",repeat: -1, yoyo:true }) 

  tl1.fromTo(".el-01", { opacity: 1, y: 0}, { duration: 2, y: -10, opacity: 0, ease: "easeIn"})
      .fromTo(".el-02", { opacity: 1, scale:1}, { duration: 2, y: -10, opacity: 0, ease: "easeIn"})
      .fromTo('.el-03', { opacity: 1, scale:1}, {duration: 2, opacity: 0, y:10, ease: "bounce"})
      
  //scene-02

  .fromTo(".scene-02", { opacity: 0}, { duration: 2, opacity: 1, ease: "easeIn"},)
  .fromTo(".el-04", { opacity: 0}, { duration: 2, y: -10, opacity: 1, ease: "easeIn"})
      .fromTo(".el-05", { opacity: 0}, { duration: 2, y: -10, opacity: 1, ease: "easeIn"})
      .fromTo(".el-06", { opacity: 0}, { duration: 2, y: -10, opacity: 1, ease: "easeIn"})
      .fromTo(".cadre", { opacity: 1}, { delay:150, duration: 0.5, opacity: 0, scale:0, ease: "easeIn"})
      .fromTo(".scene-02", { opacity: 1}, { duration: 3, opacity: 0, ease: "easeIn"})
      .fromTo(".scene-01", { opacity: 1}, { duration: 6, opacity: 0, ease: "easeIn"})

  // scene-03
  //.fromTo(".partie-1", { y: 0}, { y:-1000, duration: 10, opacity: 1, ease: "easeIn"},'-=1.85')
        //.fromTo(".scene-02", { opacity: 1}, { duration: 3, opacity: 0, ease: "easeIn"})
      //.fromTo(".scene-01", { opacity: 1}, { duration: 6, opacity: 0, ease: "easeIn"})
      tl2.fromTo(".scene-03", { opacity: 0}, { duration: 2, opacity: 1, ease: "easeIn"},'-=100')
  //.fromTo(".scene-03", { position: "relative"}, { duration: 0, position: "sticky", ease: "easeIn"})
  .fromTo(".el-07", { opacity: 0}, { duration: 2, y: -10, opacity: 1, ease: "easeIn"},'-=1.85')
      .fromTo(".el-07  #r", { x: 120}, { duration: 2, x:0, opacity: 1, ease: "bounce"},'-=1.85')
      .fromTo(".el-07  #u", { y: -120, x: -120}, { duration: 2, y:0, x:0 , opacity: 1, ease: "easeIn"},'-=1.85')
      .fromTo(".el-07  #w", { y: -120}, { duration: 2, y:0, opacity: 1, ease: "easeIn"},'-=1.85')
      .fromTo(".el-07  #v", { x: -120}, { duration: 2, x:0, opacity: 1, ease: "easeIn"},'-=1.85')
      .fromTo(".el-07  #q", { y: 120, x: 10}, { duration: 2, x:0 , y:0 , opacity: 1, ease: "easeIn"},'-=1.85')
      .fromTo(".el-07  #j", { y: 120, x: 50}, { duration: 2, x:0 , y:0 , opacity: 1, ease: "bounce"},'-=1.85')
      .fromTo("#fil-01",  { drawSVG:0}, {duration: 6, stagger: 0.1, drawSVG:true, ease: "easeIn"},'-=1.85')
      .fromTo("#fil-02",  { drawSVG:0}, {duration: 6, stagger: 0.1, drawSVG:true, ease: "easeIn"},'-=1.85')
      .fromTo("#fil-03",  { drawSVG:0}, {duration: 6, stagger: 0.1, drawSVG:true, ease: "easeIn"},'-=1.85')
      .fromTo("#fil-04",  { drawSVG:0}, {duration: 6, stagger: 0.1, drawSVG:true, ease: "easeIn"},'-=1.85')
      .fromTo("#c path",  { drawSVG:0}, {duration: 6, stagger: 0.1, drawSVG:true, ease: "easeIn"},'-=1.85')

    tl2_piece.to("#f", {
        motionPath: {
            path: "#fil-01",
            align: "#fil-01",
            alignOrigin: [0.5, 0.5],
            autoRotate: true
        },
        transformOrigin: "50% 50%",
        duration: 0.5,
        ease: "power1.inOut"
    })
    .to("#f", {
        motionPath: {
            path: "#fil-02",
            align: "#fil-02",
            alignOrigin: [0.5, 0.5],
            autoRotate: true
        },
        transformOrigin: "50% 50%",
        duration: 0.5,
        ease: "power1.inOut"
    })  
    .to("#f", {
      motionPath: {
          path: "#fil-03",
          align: "#fil-03",
          alignOrigin: [0.5, 0.5],
          autoRotate: true
      },
      transformOrigin: "50% 50%",
      duration: 0.5,
      ease: "power1.inOut"
    })
    .to("#f", {
      motionPath: {
          path: "#fil-04",
          align: "#fil-04",
          alignOrigin: [0.5, 0.5],
          autoRotate: true
      },
      transformOrigin: "50% 50%",
      opacity: 0,
      duration: 1,
      ease: "power1.inOut"
    }) 


    // scene-04    

    tl3.fromTo(".scene-04", { opacity: 0}, { duration: 0.5, opacity: 1, ease: "easeIn"})
      //.fromTo(".scene-04", { position: "relative"}, { duration: 0, position: "sticky", ease: "easeIn"})
      .fromTo(".el-08", { opacity: 0}, { duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo(".el-09", { opacity: 0}, { duration: 0.5, opacity: 1, scale:1, ease: "bounce", delay: 0 })
      .fromTo(".el-10", { opacity: 0}, { duration: 0.7, opacity: 1, scale:1, ease: "bounce", delay: 0 })
      .fromTo(".el-11", { opacity: 0}, { duration: 0.8, opacity: 1, scale:1, ease: "bounce", delay: 0 })
      //.fromTo(".scene-04", { position: "sticky"}, { duration: 0, position: "relative", ease: "easeIn"})
      .fromTo(".el-12-bas", { opacity: 0}, { duration: 0.8, opacity: 1, scale:1, ease: "bounce", delay: 0 })


    // scene-05

    tl4.fromTo(".scene-05", { opacity: 0}, { duration: 0.5, opacity: 1, ease: "easeIn"})
      //.fromTo(".el-12", { opacity: 0} ,{ duration: 0, opacity: 1, ease: "elastic"})
      .fromTo(".el-12 #c path",  { opacity: 0, drawSVG:0}, {duration: 2, opacity: 1, stagger: 0.1, drawSVG:true, ease: "easeIn"})
      .fromTo(".el-12 #fond", { opacity: 0, scale: 1},{ duration: 0.5, scale: 1, opacity: 1, ease: "easeIn"})
      .fromTo(".el-12 #titre", { opacity: 0},{ duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo(".el-12 #paragraphe-1", { opacity: 0},{ duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo(".el-12 #paragraphe-2", { opacity: 0},{ duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo(".el-12 .adie", { opacity: 0},{ duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo(".el-12 #d",  { opacity: 0, drawSVG:0}, {duration: 0.5, opacity: 1, stagger: 0.1, drawSVG:true, ease: "easeIn"})

      //.fromTo(".el-12 #piecette1", { opacity: 0, scale: 0.4},{ duration: 0.5, scale: 1, opacity: 1, ease: "bounce"})
      
      .fromTo(".el-12 #piecette2", { opacity: 0, scale: 0.4},{ duration: 0, scale: 1, opacity: 0, ease: "easeIn"})    

      .fromTo("#_x30_1-particuliers", { opacity: 0, scale:0.5},{ scale: 1, duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_2-entreprises", { opacity: 0, scale: 0.5},{ scale: 1, duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_3-collectivites", { opacity: 0, scale: 0.5},{ scale: 1, duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_4-professionnels", { opacity: 0, scale: 0.5},{ scale: 1, duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_5-autoentrepreneurs", { opacity: 0, scale: 0.5},{ scale: 1, duration: 0.5, opacity: 1, ease: "easeIn"})

      
      .to(".el-12 #piecette1", {
        motionPath: {
            path: ".el-12 #c path",
            align: ".el-12 #c path",
            alignOrigin: [0.5, 0.5],
            autoRotate: true
        },
        transformOrigin: "50% 50%",
        duration: 3,
        ease: "power1.inOut"
    })
    .to(".el-12 #piecette1",{  duration: 0.5, opacity: 0, ease: "easeIn"})


  // scene-06
  tl5.fromTo(".scene-06", { opacity: 0}, { duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo("#fond", { opacity: 0, scale:1},{ duration: 0.25, scale: 1, opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_01-titre", { opacity: 0},{ duration: 0.25, opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_02-particuliers", { opacity: 0, scale:1},{ duration:0.25, scale:1,  opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_03-fleche", { opacity: 0},{ duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_04-entreprises", { opacity: 0, scale:1},{ duration: 0.25, scale:1, opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_05-fleche", { opacity: 0},{ duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_06-fournisseurs", { opacity: 0, scale:1},{ duration: 0.25, scale:1, opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_07-fleche", { opacity: 0},{ duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_08-salaries", { opacity: 0, scale:1},{ duration: 0.25, scale:1, opacity: 1, ease: "easeIn"})
      .fromTo("#_x30_09-etat", { opacity: 0},{ duration: 0.25, opacity: 1, ease: "easeIn"})
      .fromTo("#_x31_10-achats", { opacity: 0},{ duration: 0.25, opacity: 1, ease: "easeIn"})
      .fromTo("#_x31_3-titre-fleche", { opacity: 0},{ duration: 0.25, opacity: 1, ease: "easeIn"})      
      .fromTo("#_x31_12-libelle-fleche-finale", { opacity: 0},{ duration: 0.5, opacity: 1, ease: "easeIn"})
      .fromTo(".pointer-top", { opacity: 0},{ duration: 0.25, opacity: 1, ease: "bounce"})
      .fromTo("#_x31_11-fleche-finale path",  { drawSVG:0}, {duration: 1, stagger: 0.1, drawSVG:true, ease: "easeIn"})
      .fromTo("#petitepiece", { opacity: 0},{ duration: 0.25, opacity: 1, ease: "easeIn"})
      .fromTo("#_x31_4-pointe", { opacity: 0},{ duration: 0.25, opacity: 1, ease: "easeIn"})
      .to("#petitepiece", {
        motionPath: {
            path: "#_x31_11-fleche-finale path",
            align: "#_x31_11-fleche-finale path",
            alignOrigin: [0.5, 0.5],
            autoRotate: true
        },
        transformOrigin: "50% 50%",
        opacity:0,
        duration: 3,
        ease: "power1.inOut"
    })
    //.fromTo("#petitepiece", { opacity: 1},{ duration: 0.25, opacity: 0, ease: "easeIn"})

} 

//fin de fonction desktop








function animationMobile() {
  // scroll trigger
  gsap.registerPlugin(ScrollTrigger);
      

  // declaration de mes différentes timelines (une par scène)

/*
  const tl_mobile = gsap.timeline({
      scrollTrigger: {
      trigger: ".trig",
      scrub: 0.1,
      pin: false,
      start: "top center",
      end: "bottom center",
      //markers: true,
      toggleActions: "play none reset reset",
    }
  });

*/


  // pour chacune de mes timemline je vais définir les differentes micro animations
/*
  tl_mobile.fromTo("#_x30_1-titre", { opacity: 0, scale:1}, {duration: 2, scale:1, opacity: 1, ease: "easeIn"})
      //.fromTo("#_x30_8-map-fonds",  { opacity: 0, y:0 }, {duration: 2, y:-10, opacity: 1, ease: "easeIn"})
      .to("#h", {
        motionPath: {
            path: "#d path",
            align: "#d path",
            alignOrigin: [0.5, 0.5],
            autoRotate: true
        },
        transformOrigin: "50% 50%",
        duration: 3,
        ease: "power1.inOut"
    })
    */

    gsap.registerPlugin(ScrollTrigger);

    function apparitionElement(){
        
        const boxes = gsap.utils.toArray('.fade');
        
        boxes.forEach(box => {
            
            var boxDistanceToTop = window.pageYOffset + box.getBoundingClientRect().top;
            var scrubStart = '60';
            var scrubEnd = '80';
    
            gsap.set(box,{opacity: 0})
            gsap.to(box, { 
                scrollTrigger: {
                    trigger: box,
                    toggleActions: "play none none reset",
                    start: "top "+scrubStart+"%",
                    end:   "top "+scrubEnd+"%",
                    //markers: true,
                    scrub: 0.5,
                    id: 'apparitionElement',
                },
            scale: 1,
            opacity: 1,
            duration: 2
            })
        });
    }

    
apparitionElement();

//fin de fonction mobile


} 






























  // js du quizz

const scrollDemo = document.querySelector("body");
const output = document.querySelector(".output");

window.addEventListener('scroll',(event) => { 
  
  // on va met à jour la jauge au fur et a mesure du scrolling de la page

  //console.log(scrollDemo.scrollTop);
  let hauteur = $(document).height();
  //console.log('Scrolling... '+ hauteur);
  var pc = ((scrollDemo.scrollTop*100)/(hauteur-969));
  console.log(pc);
  $('.jauge span').css('width',pc+'%');

    if(pc>18) {
        $(".nav1").addClass('on');
    }
    if(pc<18) {
      $(".nav1").removeClass('on');
  }

    if(pc>48) {
      $(".nav2").addClass('on');
    }
    if(pc<48) {
      $(".nav2").removeClass('on');
    }

    if(pc>72) {
      $(".nav3").addClass('on');
    }
    if(pc<72) {
      $(".nav3").removeClass('on');
    }

    if(pc>94) {
      $(".nav4").addClass('on');
    }
    if(pc<94) {
      $(".nav4").removeClass('on');
    }


    if(pc>90) {
      $(".flecheretour").addClass('actif');
    }
  
});


  $( ".bt_nav span, a.nav" ).on( "click", "", function() {

    let bt = $(this).attr('data-bt');

    $(".bt_nav span").removeClass('ok');
    $(".nav-tabs li").removeClass('active');
    $(".tab-pane").removeClass('active');

    $(this).addClass('ok');
    $("#onglet"+bt).addClass('active');
    $(".question"+bt).addClass('active');
    //alert(bt);

  });


  $( ".close" ).on( "click", "", function() {
    $(".modale").addClass('off');
  });


  $( ".reponse" ).on( "click", "", function() {
    let reponse = $(this).attr('data-reponse');

    if (reponse=="1") {
      $(this).addClass("vert");
    } else {
      $(this).addClass("rouge");
    }
  });

  $( ".question2 .reponse" ).on( "click", "", function() {
    let reponse = $(this).attr('data-reponse');
    $(".question2 .reponse").addClass("vert");
  });
  

  $( ".pointer-quizz" ).on( "click", "", function() {
    $(".modale").removeClass("off");
  });


  $(".mobile .pointer-top").click(function() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  });

  let marge = $(window).height();
  $(".scene-06 .pointer-top").click(function() {
    $("html, body").animate({ scrollTop: $(".partie-2").offset().top-(marge/3) }, "slow");
    return false;
  });



  function installMediaQueryWatcher(mediaQuery, layoutChangedCallback) {
    var mql = window.matchMedia(mediaQuery);
    mql.addListener(function (e) { return layoutChangedCallback(e.matches); });
    layoutChangedCallback(mql.matches);
  }



  installMediaQueryWatcher("(min-width: 600px)", function(matches) {
  
    if (matches) {
      
     //alert("> 600");
     animationDesktop();
      
    } else {
      animationMobile();
      //alert("< 600");
    }
  });


